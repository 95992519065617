import { Session } from '@supabase/supabase-js'
import { useState, useEffect } from 'react'
import { HiCheckCircle } from "react-icons/hi2";
import { supabase } from '../supabaseClient'
import './../index.css'
import { STRIPE_PRICES, IS_DEVELOPMENT } from '../config/constants';
import { useSearchParams } from 'react-router-dom';

interface PricingPageProps {
  session: Session | null;
  isHomePage?: boolean;
}

export const PricingPage = ({ session, isHomePage = false }: PricingPageProps) => {
  const [loading, setLoading] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<string | null>(null)
  const [billingInterval, setBillingInterval] = useState<'monthly' | 'yearly'>('monthly')
  const [searchParams] = useSearchParams();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const paymentStatus = searchParams.get('payment');
    if (paymentStatus === 'success') {
      setShowSuccessMessage(true);
      // Clear the URL parameter after showing the message
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('payment');
      window.history.replaceState({}, '', window.location.pathname);
      
      // Hide the message after 5 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
    }
  }, [searchParams]);

  const prices = {
    free: { monthly: 0, yearly: 0 },
    pro: { 
      monthly: 5.99,
      yearly: 60,
      // Calculate the monthly equivalent when paying yearly
      yearlyMonthly: (60 / 12).toFixed(2)
    }
  }

  useEffect(() => {
    const fetchUserPlan = async () => {
      // Only fetch if we have a valid session with a user ID
      if (!session?.user?.id) {
        return;
      }

      const { data, error } = await supabase
        .from('users')
        .select('purchased_plan')
        .eq('id', session.user.id)
        .single()

      if (error) {
        console.error('Error fetching user plan:', error)
        return
      }

      setCurrentPlan(data.purchased_plan)
    }

    fetchUserPlan()
  }, [session?.user?.id])

  const handleProUpgrade = async () => {
    if (!session?.user) {
      console.log('User not logged in');
      return;
    }

    try {
      setLoading(true)
      console.log('Starting upgrade process...')

      const priceId = STRIPE_PRICES[IS_DEVELOPMENT ? 'development' : 'production'][billingInterval];

      const { data, error } = await supabase.functions.invoke('flippy-stripe-checkout', {
        body: {
          priceId,
          userId: session.user.id,
          email: session.user.email
        }
      });

      if (error) {
        console.error('Error creating checkout session:', error);
        return;
      }

      if (data?.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  const features = {
    free: [
      '200 Saved Content',
      'Surprise Discovery on Every Tab',
      'Interactive Content Tour',
      'Timer Function',
    ],
    pro: [
      'Unlimited Saved Content',
      'Surprise Discovery on Every Tab',
      'Interactive Content Tour',
      'Timer Function',
      'Randomized Tour',
      'Priority Support',
    ]
  }

  return (
    <>
      {showSuccessMessage && (
        <div className="mb-8 p-4 bg-green-50 border border-green-200 rounded-lg">
          <div className="flex items-center">
            <HiCheckCircle className="h-5 w-5 text-green-400 mr-2" />
            <p className="text-green-700">
              Thank you! Your payment was successful. Welcome to Flippy Pro!
            </p>
          </div>
        </div>
      )}
      <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${!isHomePage ? 'pt-8' : ''}`}>
        {/* Billing interval toggle */}
        <div className="sm:flex sm:flex-col sm:align-center">
          <div className="relative bg-white rounded-lg p-0.5 flex self-center">
            <div className="relative flex bg-gray-100 rounded-lg p-1">
              <button
                onClick={() => setBillingInterval('monthly')}
                className={`${
                  billingInterval === 'monthly'
                    ? 'bg-white shadow-sm text-gray-900'
                    : 'text-gray-600 hover:text-gray-900'
                } relative py-2 px-6 rounded-md text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 transition-all duration-200`}
              >
                Monthly
              </button>
              <button
                onClick={() => setBillingInterval('yearly')}
                className={`${
                  billingInterval === 'yearly'
                    ? 'bg-white shadow-sm text-gray-900'
                    : 'text-gray-600 hover:text-gray-900'
                } relative py-2 px-6 rounded-md text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 transition-all duration-200`}
              >
                Yearly
                <span className="absolute -top-4 left-1/2 -translate-x-1/2 px-3 py-1 rounded-full bg-green-100 text-green-700 text-xs font-medium shadow-sm">
                  Save {Math.round((1 - (prices.pro.yearly / (prices.pro.monthly * 12))) * 100)}%
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* Pricing table */}
        <div className="mt-8 space-y-4 sm:mt-12 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto">
          {/* Free Plan */}
          <div className="rounded-2xl border border-gray-200 shadow-sm p-8 hover:border-gray-300 hover:shadow-md hover:-translate-y-1 transition-all duration-300 ease-in-out">
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 flex items-center">
                Free Plan
                <span className="ml-3 inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  Get Started
                </span>
              </h2>
              <div className="mt-4 min-h-[88px]">
                <p>
                  <span className="text-4xl font-bold tracking-tight text-gray-900">€0</span>
                  <span className="text-base font-medium text-gray-500">/forever</span>
                </p>
              </div>
            </div>

            <div className="space-y-0 border-t border-gray-100">
              {features.free.map((feature, index) => (
                <div 
                  key={feature}
                  className={`py-4 ${
                    index !== features.free.length - 1 ? 'border-b border-gray-100' : ''
                  }`}
                >
                  <div className="flex items-start group">
                    <HiCheckCircle className="h-6 w-6 text-gray-500 flex-shrink-0 group-hover:scale-110 group-hover:rotate-3 transition-transform duration-200" />
                    <span className="ml-3 text-base text-gray-700 group-hover:text-gray-900 transition-colors duration-200">{feature}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pro Plan */}
          <div className={`rounded-2xl p-8 ${
            currentPlan === 'pro' 
              ? 'border-2 border-teal-500 ring-2 ring-teal-500 ring-opacity-50 shadow-teal-100' 
              : 'border border-gray-200 hover:border-teal-300'
          } shadow-sm hover:shadow-md hover:-translate-y-1 transition-all duration-300 ease-in-out relative bg-white`}>
            {currentPlan === 'pro' ? (
              <span className="absolute top-0 right-6 transform -translate-y-1/2 inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-teal-100 text-teal-600 shadow-sm">
                Current Plan
              </span>
            ) : (
              <span className="absolute top-0 right-6 transform -translate-y-1/2 inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-amber-100 text-amber-600 shadow-sm">
                Most Popular
              </span>
            )}
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 flex items-center">
                Pro Plan
                <span className="ml-3 inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-teal-100 text-teal-800">
                  Unlock All Features
                </span>
              </h2>
              <div className="mt-4 min-h-[88px]">
                <p>
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    €{billingInterval === 'monthly' ? prices.pro.monthly : prices.pro.yearlyMonthly}
                  </span>
                  <span className="text-base font-medium text-gray-500">/mo</span>
                  <span className="text-sm text-gray-500 ml-1">(excl. VAT)</span>
                  {billingInterval === 'yearly' && (
                    <span className="block text-sm text-gray-500 mt-1">
                      Billed €{prices.pro.yearly}/year
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div className="space-y-0 border-t border-gray-100">
              {features.pro.map((feature, index) => (
                <div 
                  key={feature}
                  className={`py-4 ${
                    index !== features.pro.length - 1 ? 'border-b border-gray-100' : ''
                  }`}
                >
                  <div className="flex items-start group">
                    <HiCheckCircle className="h-6 w-6 text-teal-500 flex-shrink-0 group-hover:scale-110 group-hover:rotate-3 transition-transform duration-200" />
                    <span className="ml-3 text-base text-gray-700 group-hover:text-gray-900 transition-colors duration-200">{feature}</span>
                  </div>
                </div>
              ))}
            </div>

            {currentPlan !== 'pro' && (
              <button
                className="mt-8 w-full inline-flex justify-center py-3 px-6 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all duration-200 hover:shadow-lg hover:shadow-teal-100 hover:-translate-y-0.5 active:translate-y-0"
                onClick={handleProUpgrade}
                disabled={loading}
              >
                {loading ? (
                  <span className="inline-flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </span>
                ) : (
                  'Upgrade Now'
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
