import { useRef, useState, useEffect } from 'react';
import { HiArrowRight, HiChevronDown } from "react-icons/hi2";
import { IoLogoChrome } from "react-icons/io";
import { Navbar } from '../components/Navbar';
import { PricingPage } from './PricingPage';
import { Session } from '@supabase/supabase-js';
import { getExtensionURL } from '../utils/analytics';

interface HomePageProps {
  session: Session | null;
  activeSection?: string;
}

export const HomePage = ({ session, activeSection }: HomePageProps) => {
  const sectionsRef = useRef<Array<HTMLElement | null>>([]);
  const [activeWord, setActiveWord] = useState<string>('Rediscover');

  useEffect(() => {
    const words = ['Revisit!', 'Rediscover!', 'Keep!', 'Manage!'];
    let currentIndex = 0;
    
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % words.length;
      setActiveWord(words[currentIndex]);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // If activeSection is provided, scroll to it
    if (activeSection) {
      const sectionMap: { [key: string]: number } = {
        'hero': 0,
        'scenarios': 1,
        'features': 2,
        'pricing': 3,
        'cta': 4
      };
      const sectionIndex = sectionMap[activeSection];
      if (sectionIndex !== undefined && sectionsRef.current[sectionIndex]) {
        sectionsRef.current[sectionIndex]?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [activeSection]);

  const scrollToNextSection = (currentIndex: number) => {
    const nextSection = sectionsRef.current[currentIndex + 1];
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToSection = (sectionId: string) => {
    const sectionMap: { [key: string]: number } = {
      'hero': 0,
      'scenarios': 1,
      'features': 2,
      'pricing': 3,
      'cta': 4
    };
    
    const sectionIndex = sectionMap[sectionId];
    if (sectionIndex !== undefined && sectionsRef.current[sectionIndex]) {
      const navbarHeight = 80; // Increased offset to account for navbar
      const element = sectionsRef.current[sectionIndex];
      const elementPosition = element!.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - navbarHeight,
        behavior: 'smooth'
      });
    }
  };

  const handleGetExtension = () => {
    const url = getExtensionURL({
      campaign: 'homepage',
      content: 'hero_section'
    });
    window.open(url, '_blank');
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar session={session} onScrollToSection={scrollToSection} />
      
      {/* Add pt-16 (64px) to account for Navbar height */}
      <main className="pt-16">
        {/* Hero Section */}
        <section
          ref={el => sectionsRef.current[0] = el}
          className="min-h-screen relative overflow-hidden"
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div className="text-center md:text-left">
                <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold text-gray-900 mb-8 tracking-tight">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-amber-400 inline-block min-w-[300px] transition-opacity duration-500 h-[1.2em]">
                    {activeWord}
                  </span>
                  <br />
                  what matters
                </h1>
                <p className="text-xl sm:text-2xl text-gray-600 mb-12">
                  Your smart hub for blogs, articles, bookmarks, and more — all in one place.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start items-center">
                  <a
                    href={getExtensionURL({
                      campaign: 'homepage',
                      content: 'hero_section'
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGetExtension();
                    }}
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all duration-200 hover:shadow-lg hover:shadow-teal-100 hover:-translate-y-0.5"
                  >
                    <IoLogoChrome className="w-5 h-5 mr-2" />
                    Add to Chrome
                    <HiArrowRight className="ml-2 -mr-1 h-5 w-5" />
                  </a>
                  <button
                    onClick={() => scrollToNextSection(0)}
                    className="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all duration-200"
                  >
                    Learn more
                    <HiChevronDown className="ml-2 -mr-1 h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="relative w-full aspect-video rounded-lg overflow-hidden shadow-[0_0_70px_0px_rgba(45,212,191,0.3)] hover:shadow-[0_0_80px_10px_rgba(251,191,36,0.3)] transition-shadow duration-300">
                <iframe
                  src="https://www.youtube-nocookie.com/embed/2I4dW2L9qzE?autoplay=1&mute=1&loop=1&playlist=2I4dW2L9qzE&rel=0&modestbranding=1"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  loading="lazy"
                  title="Flippy Introduction Video"
                  className="absolute top-0 left-0 w-full h-full"
                />
              </div>
            </div>
          </div>
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
            <button
              onClick={() => scrollToNextSection(0)}
              className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            >
              <HiChevronDown className="h-6 w-6 text-gray-600" />
            </button>
          </div>
        </section>

        {/* Scenarios Section */}
        <section
          ref={el => sectionsRef.current[1] = el}
          className="min-h-screen bg-gray-50 py-24 px-4 sm:px-6 lg:px-8 relative"
        >
          <div className="max-w-7xl mx-auto">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
                <span className="underline decoration-4 decoration-teal-500">Why</span>{" "}
                <span className="underline decoration-4 decoration-amber-400">Flippy</span>?
              </h2>
              <p className="text-xl text-gray-600">
                💡 Ever find yourself bookmarking blogs, saving videos, or keeping countless tabs open—only to forget them? 
                If you're tired of losing track of the reads you meant to enjoy (or that watch-later queue you never revisit), 
                Flippy is here to help.
              </p>
            </div>
            <div className="grid md:grid-cols-2 gap-12">
              <div className="bg-white rounded-2xl p-8 shadow-sm">
                <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                  The Problem
                </h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">✗</span>
                    <span>Bookmarks pile up and get forgotten</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">✗</span>
                    <span>Too many open tabs cluttering your browser</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">✗</span>
                    <span>Great content gets lost in the noise</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">✗</span>
                    <span>No easy way to rediscover saved content</span>
                  </li>
                </ul>
              </div>
              <div className="bg-white rounded-2xl p-8 shadow-sm">
                <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                  The Solution
                </h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2">✓</span>
                    <span>Smart organization of all your saved content</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2">✓</span>
                    <span>Rediscover content through random suggestions</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2">✓</span>
                    <span>Guided tours through your saved items</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2">✓</span>
                    <span>Timer-based content exploration sessions</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
            <button
              onClick={() => scrollToNextSection(1)}
              className="p-2 rounded-full bg-white hover:bg-gray-100 transition-colors"
            >
              <HiChevronDown className="h-6 w-6 text-gray-600" />
            </button>
          </div>
        </section>

        {/* Features Section */}
        <section
          ref={el => sectionsRef.current[2] = el}
          className="min-h-screen py-24 px-4 sm:px-6 lg:px-8 relative"
        >
          <div className="max-w-7xl mx-auto">
            <h2 className="text-4xl font-bold text-gray-900 mb-16 text-center">
              <span className="underline decoration-4 decoration-teal-500">Your favorite</span>{" "}
              <span className="underline decoration-4 decoration-amber-400">content</span>{" "}
              <span className="underline decoration-4 decoration-teal-500">reimagined</span>
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Smart Organization",
                  description: "The modern content manager that automatically organizes your content into collections. Save articles, blogs, and more with a single click.",
                  icon: "🗂️"
                },
                {
                  title: "Tour",
                  description: "Take a guided journey through your read-it-later list. Perfect for catching up on saved articles and bookmarks.",
                  icon: "🎯"
                },
                {
                  title: "Random Discovery",
                  description: "Unlimited inspiration through your saved content. Each new tab reveals a forgotten gem from your bookmark collection.",
                  icon: "🎲"
                },
                {
                  title: "Timer Mode",
                  description: "Set a pace and let Flippy automatically cycle through your content. Great for reading sessions.",
                  icon: "⏱️"
                },
                {
                  title: "Cross Browsers",
                  description: "Access your collections seamlessly across different browsers.",
                  icon: "🌐",
                  tag: "Coming Soon"
                },
                {
                  title: "Cross-device Sync",
                  description: "Your collections are always in sync across all your devices.",
                  icon: "🔄",
                  tag: "Coming Soon"
                }
              ].map((feature, index) => (
                <div
                  key={index}
                  className="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md hover:-translate-y-1 transition-all duration-300 ease-in-out relative group"
                >
                  <div className="text-4xl mb-4 group-hover:scale-110 transition-transform duration-300">{feature.icon}</div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2 flex items-center gap-2">
                    {feature.title}
                    {feature.tag && (
                      <span className="text-xs font-medium px-2 py-1 rounded-full bg-amber-100 text-amber-800">
                        {feature.tag}
                      </span>
                    )}
                  </h3>
                  <p className="text-gray-600">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
            <button
              onClick={() => scrollToNextSection(2)}
              className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            >
              <HiChevronDown className="h-6 w-6 text-gray-600" />
            </button>
          </div>
        </section>

        {/* Pricing Section */}
        <section
          ref={el => sectionsRef.current[3] = el}
          className="min-h-screen bg-gray-50 pt-16 pb-24 relative"
        >
          <PricingPage session={session} isHomePage={true} />
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
            <button
              onClick={() => scrollToNextSection(3)}
              className="p-2 rounded-full bg-white hover:bg-gray-100 transition-colors"
            >
              <HiChevronDown className="h-6 w-6 text-gray-600" />
            </button>
          </div>
        </section>

        {/* Final CTA Section */}
        <section
          ref={el => sectionsRef.current[4] = el}
          className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-24 px-4 sm:px-6 lg:px-8 flex items-center justify-center"
        >
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-gray-900 mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-amber-400">
                Ready to get started?
              </span>
            </h2>
            <p className="text-xl text-gray-600 mb-12">
              Install Flippy and never lose track of the content you love again!
            </p>
            <a
              href={getExtensionURL({
                campaign: 'homepage',
                content: 'hero_section'
              })}
              onClick={(e) => {
                e.preventDefault();
                handleGetExtension();
              }}
              className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-lg shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all duration-200 hover:shadow-lg hover:shadow-teal-100 hover:-translate-y-0.5"
            >
              <IoLogoChrome className="w-6 h-6 mr-2" />
              Get Flippy for Chrome
            </a>
          </div>
        </section>
      </main>
    </div>
  );
};
