export const EXTENSION = {
    URL: 'https://chromewebstore.google.com/detail/lieigododmdmffpoianaddkpiihljfdo',
    NAME: 'Flippy',
    SHORT_NAME: 'Flippy',
    DESCRIPTION: 'Revisit and manage your favorite content'
} as const;

export const STRIPE_PRICES = {
    production: {
        monthly: 'price_1QnfSj1irze4EbJjiqkv6tnK',
        yearly: 'price_1QnfTt1irze4EbJjFmdF4I8S'
    },
    development: {
        monthly: 'price_1QoNJ61irze4EbJj4ZXtEHsT',
        yearly: 'price_1QoNKC1irze4EbJjmkGHpZjd'
    }
} as const;

// You can set this to true locally for testing
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
