import { Session } from '@supabase/supabase-js';
import { Button } from 'primereact/button';

interface UserWelcomePageProps {
    session: Session | null;
    onNavigate: (tab: string) => void;
}

export const UserWelcomePage = ({ session, onNavigate }: UserWelcomePageProps) => {

    return (
        <div className="flex flex-col items-center justify-center min-h-[calc(100vh-200px)] p-8 text-center">
            <div className="max-w-2xl">
                <h1 className="text-3xl font-bold mb-6">Welcome to Flippy!</h1>
                
                <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
                    <h2 className="text-xl font-semibold mb-4">You can now:</h2>
                    
                    <div className="space-y-4">
                        <div className="p-4 bg-blue-50 rounded-lg mb-8">
                            <h3 className="font-medium text-blue-800 mb-2">
                                Return to the Extension
                            </h3>
                            <p className="text-gray-600">
                                Go back to the Flippy extension to start saving and organizing your content.
                            </p>
                        </div>
                        
                        <div className="p-4 bg-purple-50 rounded-lg">
                            <h3 className="font-medium text-purple-800 mb-2">
                                ⭐️ Upgrade Your Plan
                            </h3>
                            <p className="text-gray-600 mb-4">
                                Get access to premium features and enhance your Flippy experience.
                            </p>
                            <Button
                                label="View Plans"
                                className="p-button-outlined p-button-secondary"
                                onClick={() => onNavigate('plans')}
                            />
                        </div>
                    </div>
                </div>
                
                <p className="text-sm text-gray-500">
                    Need help? Check out our documentation or contact support.
                </p>
            </div>
        </div>
    );
};
