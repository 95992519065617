import { createClient } from '@supabase/supabase-js'

const isProd = window.location.hostname !== 'localhost'

export const supabase = createClient(
  isProd 
    ? 'https://dyyyiaemcpmzynhvpevw.supabase.co'
    : 'http://127.0.0.1:54321',
  isProd
    ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR5eXlpYWVtY3BtenluaHZwZXZ3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzExMzMwMzUsImV4cCI6MjA0NjcwOTAzNX0.fQjyuveRyhnRDjI8MJuFa6gh1sWj7M9LeN9WMDub1c0'
    : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0'
)