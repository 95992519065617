import { useState, useEffect, useRef } from 'react';
import { Session, AuthChangeEvent } from '@supabase/supabase-js';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { SuccessPage } from './pages/SuccessPage';
import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { UserHomePage } from './pages/UserHomePage';
import './index.css'
import './App.css'

// Marketing site sections
const marketingSections = ['pricing', 'about', 'features'];

function App() {
    const [session, setSession] = useState<Session | null>(null);
    const [loading, setLoading] = useState(true);
    const initialSetupDone = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Initial setup effect
    useEffect(() => {
        if (initialSetupDone.current) return;
        initialSetupDone.current = true;

        // Set up auth state change listener
        const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event: AuthChangeEvent, currentSession) => {
            console.log("Auth state changed:", event, currentSession?.user?.email);
            
            if (event === 'SIGNED_OUT') {
                console.log('User signed out, clearing session');
                setSession(null);
                localStorage.clear();
                setLoading(false);
                // Redirect to home page on logout
                if (location.pathname !== '/') {
                    navigate('/', { replace: true });
                }
            } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
                console.log('User signed in or token refreshed');
                if (currentSession) {
                    setSession(currentSession);
                } else {
                    // If no session, try to refresh it
                    const { data: { session: refreshedSession } } = await supabase.auth.getSession();
                    setSession(refreshedSession);
                }
                setLoading(false);
            }
        });

        // Initial session check
        supabase.auth.getSession().then(({ data: { session: initialSession } }) => {
            setSession(initialSession);
            setLoading(false);
        });

        return () => {
            console.log('Cleaning up App component');
            subscription.unsubscribe();
        };
    }, [navigate, location.pathname]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    return (
        <Routes>
            {/* Marketing Site Routes */}
            <Route path="/" element={<HomePage session={session} />} />
            <Route path="/login" element={<LoginPage />} />
            {marketingSections.map(section => (
                <Route 
                    key={section}
                    path={`/${section}`}
                    element={<HomePage session={session} activeSection={section} />}
                />
            ))}

            {/* Authentication Success */}
            <Route 
                path="/auth/callback" 
                element={
                    session ? (
                        <Navigate to="/my-flippy/home" replace />
                    ) : (
                        <SuccessPage session={session} />
                    )
                } 
            />

            {/* Protected App Routes */}
            <Route
                path="/my-flippy"
                element={
                    session ? (
                        <UserHomePage session={session} />
                    ) : (
                        <Navigate to="/login" state={{ from: '/my-flippy' }} replace />
                    )
                }
            />
            <Route
                path="/my-flippy/:page"
                element={
                    session ? (
                        <UserHomePage session={session} />
                    ) : (
                        <Navigate to="/login" state={{ from: location.pathname }} replace />
                    )
                }
            />

            {/* Legacy Routes - Redirect to new structure */}
            <Route
                path="/home"
                element={<Navigate to="/my-flippy/home" replace />}
            />
            <Route
                path="/home/*"
                element={<Navigate to="/my-flippy/home" replace />}
            />
            <Route
                path="/dashboard"
                element={<Navigate to="/my-flippy/home" replace />}
            />
            <Route
                path="/dashboard/*"
                element={<Navigate to="/my-flippy/home" replace />}
            />
        </Routes>
    );
}

export default App;
