import { Session } from '@supabase/supabase-js';
import { useEffect } from 'react';
import { Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { PricingPage } from './PricingPage';
// import { ContentPage } from './ContentPage';
import { UserWelcomePage } from './UserWelcomePage';
import { Navbar } from '../components/Navbar';
import { supabase } from '../supabaseClient';
import { HiOutlineLogout } from "react-icons/hi";

interface UserHomePageProps {
    session: Session | null;
}

type ActiveTab = 'home' | 'content' | 'plans';

export const UserHomePage = ({ session }: UserHomePageProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { page } = useParams();
    
    const activeTab = (page || 'home') as ActiveTab;

    useEffect(() => {
        // Redirect to /my-flippy/home if we're at /my-flippy
        if (location.pathname === '/my-flippy') {
            navigate('/my-flippy/home', { replace: true });
        }
    }, [location.pathname, navigate]);

    if (!session) {
        return <Navigate to="/login" />;
    }

    const handleLogout = async () => {
        try {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;
            
            // Clear any local storage or state
            localStorage.clear();
            
            // Force a page reload to clear all state
            window.location.href = '/';
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const handleScrollToSection = (sectionId: string) => {
        // No-op since we don't need scroll functionality here
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'home':
                return <UserWelcomePage session={session} onNavigate={(tab) => navigate(`/my-flippy/${tab}`)} />;
            // case 'content':
            //     return <ContentPage session={session} />;
            case 'plans':
                return <PricingPage session={session} />;
            default:
                return <UserWelcomePage session={session} onNavigate={(tab) => navigate(`/my-flippy/${tab}`)} />;
        }
    };

    return (
        <div className="flex flex-col h-screen bg-gray-100 pt-[72px]">
            {/* Navbar */}
            <Navbar 
              onScrollToSection={handleScrollToSection} 
              showNavigation={false}
              session={session}
            />

            <div className="flex flex-1 overflow-hidden">
                {/* Sidebar */}
                <div className="w-64 bg-white shadow-lg">
                    <div className="flex flex-col h-full">
                        <nav className="flex-1 mt-4">
                            <button
                                onClick={() => navigate('/my-flippy/home')}
                                className={`w-full text-left px-4 py-2 ${
                                    activeTab === 'home'
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-600 hover:bg-gray-50'
                                }`}
                            >
                                Home
                            </button>
                            {/* <button
                                onClick={() => navigate('/my-flippy/content')}
                                className={`w-full text-left px-4 py-2 ${
                                    activeTab === 'content'
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-600 hover:bg-gray-50'
                                }`}
                            >
                                Content
                            </button> */}
                            <button
                                onClick={() => navigate('/my-flippy/plans')}
                                className={`w-full text-left px-4 py-2 ${
                                    activeTab === 'plans'
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-600 hover:bg-gray-50'
                                }`}
                            >
                                Plans
                            </button>
                        </nav>
                        
                        {/* Logout Button */}
                        <div className="p-4 border-t">
                            <button
                                onClick={handleLogout}
                                className="w-full flex items-center px-4 py-2 text-gray-600 hover:bg-gray-50 rounded"
                            >
                                <HiOutlineLogout className="w-5 h-5 mr-2" />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>

                {/* Main Content */}
                <div className="flex-1 overflow-y-auto p-8">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};
