import { useNavigate } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../supabaseClient';
import { Navbar } from '../components/Navbar';

export const LoginPage = () => {
  const navigate = useNavigate();
  
  const handleScrollToSection = (sectionId: string) => {
    navigate('/#' + sectionId);
  };

  const authStyles = {
    theme: ThemeSupa,
    variables: {
      default: {
        colors: {
          brand: '#2563eb',
          brandAccent: '#1d4ed8',
          brandButtonText: 'white',
          defaultButtonBackground: 'white',
          defaultButtonBackgroundHover: '#f8fafc',
          defaultButtonBorder: 'lightgray',
          defaultButtonText: 'gray',
          dividerBackground: '#e2e8f0',
          inputBackground: 'transparent',
          inputBorder: 'lightgray',
          inputBorderHover: '#94a3b8',
          inputBorderFocus: '#2563eb',
          inputText: 'black',
          inputLabelText: 'gray',
          inputPlaceholder: 'darkgray',
        },
        space: {
          spaceSmall: '4px',
          spaceMedium: '8px',
          spaceLarge: '12px',
          labelBottomMargin: '8px',
          anchorBottomMargin: '4px',
          emailInputSpacing: '4px',
          socialAuthSpacing: '4px',
          buttonPadding: '10px 15px',
          inputPadding: '10px 15px',
        },
        fonts: {
          bodyFontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          buttonFontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          inputFontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          labelFontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        },
        fontSizes: {
          baseBodySize: '14px',
          baseInputSize: '14px',
          baseLabelSize: '14px',
          baseButtonSize: '14px',
        },
        borderWidths: {
          buttonBorderWidth: '1px',
          inputBorderWidth: '1px',
        },
        radii: {
          borderRadiusButton: '8px',
          buttonBorderRadius: '8px',
          inputBorderRadius: '8px',
        },
      },
    },
    className: {
      container: 'w-full max-w-[400px]',
      button: 'rounded-lg font-medium hover:opacity-90 transition-all duration-200',
      divider: 'my-4',
      label: 'text-sm font-medium text-gray-700',
      input: 'w-full border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500',
      loader: 'w-5 h-5 border-2 border-blue-600',
      anchor: 'text-sm text-blue-600 hover:text-blue-700 font-medium',
    },
  };

  return (
    <>
      <Navbar 
        onScrollToSection={handleScrollToSection} 
        showNavigation={true}
        session={null}
      />
      <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 flex flex-col items-center pt-40">
        <div className="w-full max-w-[400px] relative px-4">
          <div className="absolute -top-4 left-0 w-full flex justify-center">
            <span className="px-6 bg-gradient-to-b from-white to-gray-50 text-gray-900 font-semibold text-xl">
              Sign in
            </span>
          </div>
          <div className="w-full bg-white rounded-xl shadow-sm border border-gray-100 p-8">
          <Auth
            supabaseClient={supabase}
            appearance={authStyles}
            providers={['google']}
            redirectTo={`${window.location.origin}/dashboard`}
            view="sign_in"
            showLinks={false}
            onlyThirdPartyProviders
          />
          </div>
        </div>
      </div>
    </>
  );
};