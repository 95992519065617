import { EXTENSION } from '../config/constants';

// Base URL for the extension
export const EXTENSION_URL = EXTENSION.URL;

interface UTMParams {
    source: string;
    medium?: string;
    campaign?: string;
    content?: string;
    term?: string;
}

export const addUTMParams = (url: string, params: UTMParams): string => {
    const urlObj = new URL(url);
    
    urlObj.searchParams.set('utm_source', params.source);
    if (params.medium) urlObj.searchParams.set('utm_medium', params.medium);
    if (params.campaign) urlObj.searchParams.set('utm_campaign', params.campaign);
    if (params.content) urlObj.searchParams.set('utm_content', params.content);
    if (params.term) urlObj.searchParams.set('utm_term', params.term);
    
    return urlObj.toString();
};

export const getExtensionURL = (params: Partial<UTMParams> = {}): string => {
    const defaultParams: UTMParams = {
        source: 'flippy_web',
        medium: 'website',
        ...params as Partial<UTMParams>
    };

    // Chrome Web Store uses a different format for referral tracking
    // Format: ?hl=en&ref=source&utm_source=...
    const urlObj = new URL(EXTENSION.URL);
    urlObj.searchParams.set('hl', 'en');
    urlObj.searchParams.set('ref', defaultParams.source);
    
    // Also add UTM parameters for additional tracking
    Object.entries(defaultParams).forEach(([key, value]) => {
        if (value) {
            urlObj.searchParams.set(`utm_${key}`, value);
        }
    });
    
    return urlObj.toString();
};
